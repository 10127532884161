import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 910.71997 499.56">
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,499.56)">
        <g transform="scale(0.1)">
          <path
            style={{ fill: '#393536' }}
            d="m 4553.28,3183.14 -857.13,494.89 c -76.76,44.29 -176.54,68.69 -280.95,68.69 -104.42,0 -204.2,-24.4 -280.96,-68.69 l -857.13,-494.89 c -95.96,-55.41 -151.06,-139.7 -151.15,-231.4 V 1896.62 c 0,-6.38 0.49,-12.65 1.46,-18.81 8.56,-82.55 62.46,-157.87 149.69,-208.24 l 857.13,-494.88 c 76.76,-44.3 176.54,-68.69 280.96,-68.69 104.41,0 204.19,24.39 280.95,68.69 l 857.13,494.88 c 87.17,50.33 141.06,125.57 149.65,208.07 0.98,6.17 1.5,12.54 1.5,18.98 v 1055.24 c -0.09,91.58 -55.19,175.87 -151.15,231.28"
          />
          <path
            style={{ fill: '#393536' }}
            d="m 4584.43,2951.74 c -0.05,46.23 -30.41,92.42 -91.15,127.48 l -857.13,494.88 c -121.52,70.16 -320.39,70.16 -441.91,0 l -857.13,-494.88 c -60.74,-35.06 -91.09,-81.25 -91.15,-127.48 v 0 -1055.12 h 0.35 c 1.89,-44.76 31.99,-89.18 90.8,-123.1 l 857.13,-494.91 c 121.52,-70.17 320.39,-70.17 441.91,0 l 857.13,494.91 c 58.81,33.92 88.91,78.34 90.8,123.1 h 0.35 v 1055.12 0"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 2337.11,3079.22 c -121.52,-70.16 -121.52,-184.96 0,-255.12 l 857.13,-494.9 c 121.52,-70.16 320.39,-70.16 441.91,0 l 857.13,494.9 c 121.52,70.16 121.52,184.96 0,255.12 l -857.13,494.88 c -121.52,70.16 -320.39,70.16 -441.91,0 l -857.13,-494.88"
          />
          <path
            style={{ fill: '#ee2637' }}
            d="m 4044.9,3046.39 -507.01,292.69 c -30.9,17.85 -75.64,28.07 -122.69,28.07 -47.07,0 -91.82,-10.24 -122.76,-28.11 l -506.93,-292.69 c -33.83,-19.53 -54.69,-55.63 -54.69,-94.71 0,-39.06 20.86,-75.16 54.69,-94.71 l 506.99,-292.71 c 30.94,-17.83 75.64,-28.05 122.7,-28.05 47.07,0 91.81,10.24 122.75,28.11 l 506.95,292.69 c 33.85,19.53 54.67,55.63 54.67,94.71 0,39.08 -20.86,75.16 -54.67,94.71"
          />
          <path
            style={{ fill: '#bd1f1e' }}
            d="m 3537.95,2564.28 506.95,292.69 c 33.85,19.53 54.67,55.63 54.67,94.71 0,39.08 -20.86,75.16 -54.67,94.71 l -507.01,292.69 c -30.9,17.85 -75.64,28.07 -122.69,28.07 v -830.98 c 47.07,0 91.81,10.24 122.75,28.11"
          />
          <path
            style={{ fill: '#94d222' }}
            d="m 3415.2,2791.29 c -2.43,0 -4.57,0.14 -6.27,0.29 l -277.21,160.06 277.26,160.1 c 1.7,0.15 3.81,0.29 6.22,0.29 2.4,0 4.53,-0.14 6.25,-0.29 l 277.26,-160.08 -277.25,-160.08 c -1.69,-0.15 -3.84,-0.29 -6.26,-0.29"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 607.266,6.08984 442.344,520.879 275.684,6.08984 H 204.141 L 0,616.859 H 75.0391 L 242.539,87.25 408.34,615.961 478.125,616.859 645.645,87.25 811.406,616.859 h 72.422 L 678.809,6.08984 h -71.543"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 922.93,6.08984 V 465.922 h 67.187 V 6.08984 Z M 1000.61,597.66 c 0,-27.07 -19.223,-47.109 -44.516,-47.109 -25.313,0 -44.492,20.039 -44.492,47.109 0,27.051 19.179,47.11 44.492,47.11 25.293,0 44.516,-20.059 44.516,-47.11"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 1125.31,6.08984 V 653.5 h 68.07 V 6.08984 h -68.07"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 1332.95,6.08984 V 653.5 h 68.03 V 6.08984 h -68.03"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 2043.11,519.98 -80.28,-97.691 c -41.85,47.981 -102.07,79.391 -158.79,79.391 -104.7,0 -185.86,-81.149 -185.86,-187.602 0,-106.437 81.16,-188.449 185.86,-188.449 54.96,0 115.18,27.91 158.79,71.543 l 81.15,-88.133 C 1979.41,42.7617 1886.07,0 1797.07,0 c -182.34,0 -320.21,135.238 -320.21,312.332 0,176.25 140.48,308.879 325.43,308.879 88.12,0 180.6,-39.262 240.82,-101.231"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 2171.25,239.059 c 0,-76.77 47.11,-129.137 116.91,-129.137 68.95,0 116.94,52.367 116.94,129.137 0,76.761 -47.99,129.121 -116.94,129.121 -69.8,0 -116.91,-52.36 -116.91,-129.121 z m 368.2,1.742 c 0,-143.0822 -101.19,-239.961156 -251.29,-239.961156 -150.93,0 -252.14,96.878956 -252.14,239.961156 0,143.949 101.21,239.058 252.14,239.058 150.1,0 251.29,-95.109 251.29,-239.058"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 2923.24,239.059 c 0,76.761 -47.99,129.98 -116.89,129.98 -69.79,0 -117.78,-54.059 -118.65,-129.98 0.87,-76.77 47.98,-130.02 118.65,-130.02 68.9,0 116.89,53.25 116.89,130.02 z M 3056.76,6.08984 H 2923.24 V 75.0195 c -33.12,-47.9687 -83.73,-74.179656 -148.3,-74.179656 -133.48,0 -221.6,96.878956 -221.6,241.719156 0,143.089 87.25,238.16 218.09,238.16 66.32,0 117.79,-26.149 151.81,-74.141 V 653.5 h 133.52 V 6.08984"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 3462.36,279.199 c -1.75,61.93 -41.89,102.071 -103.82,102.071 -59.36,0 -99.5,-40.141 -109.95,-102.071 z m 125.63,-79.41 h -336.8 c 15.7,-59.34 61.08,-94.219 121.29,-94.219 44.51,0 86.37,17.461 118.65,49.719 L 3561.8,83.7695 C 3514.69,31.4102 3445.78,0.839844 3361.13,0.839844 c -150.08,0 -245.17,95.980456 -245.17,238.219156 0,144.832 98.61,240.8 241.7,240.8 164.88,0 241.68,-109.058 230.33,-280.07"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 3741.45,511.27 v -59.34 h 131.77 V 396.09 H 3741.45 V 6.08984 h -67.19 V 396.09 h -59.32 v 55.84 h 59.32 v 50.629 c 0,100.312 70.7,154.41 147.46,154.41 34.92,-0.86 70.66,-11.321 98.61,-32.258 l -27.95,-54.961 c -20.92,14.801 -46.23,23.551 -69.78,23.551 -44.51,0 -81.15,-28.813 -81.15,-82.031"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 3921.15,235.551 c 0,-101.192 68.91,-171.0002 164.89,-171.0002 95.97,0 164.9,69.8082 164.9,171.0002 0,100.328 -68.93,170.148 -164.9,170.148 -95.98,0 -164.89,-69.82 -164.89,-170.148 z M 4319,236.43 C 4319,98.582 4222.15,3.5 4086.04,3.5 c -136.12,0 -232.95,95.082 -232.95,232.93 0,136.992 96.83,231.211 232.95,231.211 136.11,0 232.96,-94.219 232.96,-231.211"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 4471.58,363.809 c 30.53,67.191 87.25,103.832 164.9,104.711 v -68.93 c -96.83,1.762 -157.05,-58.461 -164.9,-149.18 V 6.08984 h -68.06 V 465.922 h 68.06 V 363.809"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 5132.87,556.641 -53.2,-114.282 c -64.57,38.36 -141.35,62.821 -190.2,62.821 -46.27,0 -77.67,-16.598 -77.67,-49.77 0,-112.519 325.43,-49.711 324.59,-269.57 0,-119.5314 -103.83,-184.10172 -239.07,-184.10172 -100.33,0 -200.68,38.36332 -269.62,96.84372 l 54.96,112.566 c 62.85,-55.859 151.83,-91.617 216.4,-91.617 56.72,0 90.73,20.918 90.73,58.457 0,115.16 -325.43,47.973 -325.43,264.371 0,110.789 95.09,180.59 236.46,180.59 85.47,0 171,-26.168 232.05,-66.308"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 5641.45,6.08984 H 5507.93 V 88.9688 C 5475.66,29.6484 5421.56,0.839844 5350.9,0.839844 5247.07,0.839844 5181.62,69.7891 5181.62,180.59 v 294.898 h 134.4 V 219.012 c 0,-59.34 34,-95.141 88.12,-95.141 64.53,0.879 103.79,51.5 103.79,119.527 v 232.09 h 133.52 V 6.08984"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="m 6078.46,429.262 -46.23,-91.621 c -51.49,28.808 -103.83,45.39 -143.09,45.39 -31.44,0 -54.1,-10.492 -54.1,-34.922 0,-70.668 247.77,-34.89 246.91,-200.668 0,-95.9722 -84.65,-146.601156 -191.09,-146.601156 -75.9,0 -150.04,22.718756 -203.28,65.468756 l 43.63,90.7224 c 48.87,-37.5 111.68,-58.449 163.15,-58.449 34.04,0 59.31,11.34 59.31,36.656 0,75.024 -243.42,34.903 -243.42,199.801 0,96.82 82.91,146.582 184.98,146.582 64.57,0 130.86,-19.199 183.23,-52.359"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 6610.61,301 V 6.08984 H 6476.25 V 261.738 c 0,59.344 -34.92,95.981 -92.5,95.981 -67.17,-0.879 -108.18,-51.489 -108.18,-120.41 V 6.08984 H 6141.21 V 653.5 h 134.36 V 392.602 c 33.16,58.457 89,87.257 162.28,88.117 105.59,0 172.76,-69.797 172.76,-179.719"
          />
          <path
            style={{ fill: '#ffffff' }}
            d="M 6690.76,6.08984 V 475.488 h 134.36 V 6.08984 Z M 6830.35,609.012 c 0,-43.641 -29.67,-75.043 -72.42,-75.043 -42.73,0 -72.42,31.402 -72.42,75.043 0,43.609 29.69,75.008 72.42,75.008 42.75,0 72.42,-31.399 72.42,-75.008"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
