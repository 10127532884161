import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';

import Logo from '_images/svg/Logo';

const BG_FADE_INTERVAL_MS = 5000;

/**
 * Returns component containing page SEO optimizations.
 *
 * @returns {React.Component} Page SEO.
 */
const SEO = () => {
  const title = 'Will Code for Sushi';
  const domain = 'https://willcodeforsushi.com/';
  const canonicalURL = domain;
  const logoGoogle = `${domain}/logo/logo-google-schema.png`;
  const logoTwitter = `${domain}/logo/logo-twitter-card.png`;
  const logoOpenGraph = `${domain}/logo/logo-opengraph-card.png`;
  const description =
    'Handpicked software engineers that create Software as a Service solutions. &lt;&gt;&lt;';

  // JSON-LD Organization schema.
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@id': 'https://schema.org/Organization',
    '@type': 'Organization',
    name: title,
    url: canonicalURL,
    logo: logoGoogle,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        email: 'bartosz.bobnis+sales@willcodeforsushi.com',
        telephone: '+1-647-494-3992 (ext. 106)',
        contactType: 'Sales',
        areaServed: 'CA',
        availableLanguage: 'EN',
      },
    ],
    sameAs: ['https://ca.linkedin.com/company/willcodeforsushi'],
  };

  // JSON-LD WebPage schema.
  const webPageSchema = {
    '@context': 'https://schema.org',
    '@id': 'https://schema.org/WebPage',
    '@type': 'WebPage',
    url: domain,
    name: title,
  };

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonicalURL} />
      <meta name="description" content={description} />
      <meta name="robots" content="follow, index" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoTwitter} />
      <meta name="twitter:image:alt" content={`${title} - ${description}`} />
      <meta name="og:url" content={canonicalURL} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={logoOpenGraph} />
      <meta name="og:locale" content="en_CA" />
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema, null, 2)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(webPageSchema, null, 2)}
      </script>
    </Helmet>
  );
};

/**
 * Sets a global style to target tags outside the React Virtual DOM.
 *
 * @returns {React.Component} Styled component.
 */
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

/**
 * Component container.
 *
 * @returns {React.Component} Styled component.
 */
const Container = styled.main`
  // Background color.
  background: ${props => props.bgColor};
  transition: 1s ease-in-out;

  // Centered in the middle of page.
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  // Container dimensions.
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;

  // SVG dimensions.
  svg {
    height: 80vh;
    width: 80vw;
    max-height: 80vh;
    max-width: 80vw;
  }

  // Hyperlinks.
  a {
    color: inherit;
  }
`;

// Background colors.
const colors = {
  salmon: '#ffa384',
  nori: '#0a2200',
  tuna: '#bd1f1e',
  wasabi: '#859731',
  tako: '#580259',
};

/**
 * Returns a random hexadecimal color code.
 *
 * @returns {string} Hexadecimal color code.
 */
const getRandomColor = () => {
  const hexColors = Object.values(colors);
  const randomNumber = Math.floor(Math.random() * hexColors.length);
  return hexColors[randomNumber];
};

/**
 * Home page component.
 *
 * @returns {React.Component} Home page.
 */
const IndexPage = () => {
  const [bgColor, setBgColor] = useState(colors.salmon);

  useEffect(() => {
    window.setInterval(() => {
      setBgColor(getRandomColor());
    }, BG_FADE_INTERVAL_MS);

    return window.clearInterval();
  }, []);

  return (
    <Container bgColor={bgColor}>
      <SEO />
      <GlobalStyle />

      <a
        href="https://ca.linkedin.com/company/willcodeforsushi"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo />
      </a>
    </Container>
  );
};

export default IndexPage;
